import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Input from 'reusecore/src/elements/Input';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import ContactWrapper, {
  ContactForm,
} from './contact.style';

const ContactSection = ({ sectionHeader, sectionTitle, sectionSubTitle, btnStyle, formRow }) => {
  return (
    <ContactWrapper id="newsletterSection">
      <Container>
        <Box {...sectionHeader}>
          <Text content="Contact Us" {...sectionSubTitle} />
          <Heading content="Get In Touch" {...sectionTitle} />
        </Box>
        <Box>
        <ContactForm>
        <Text textAlign="center" content=" If you are a small business and looking for ways in which digital transformation can
              help you accelerate your business.">
            </Text>
            </ContactForm>
          <ContactForm>
         
            <form style={{width: '100%'}} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Box {...formRow}>
                <Input
                  required="true"
                  inputType="email"
                  isMaterial={false}
                  name="email"
                  label="Email"
                  aria-label="email"
                  maxlength="200"
                />
              </Box>
              <Box {...formRow}>
                <Input
                  required="true"
                  inputType="text"
                  isMaterial={false}
                  name="name"
                  label="Name"
                  aria-label="Your Name"
                  maxlength="100"
                />
              </Box>
              <Box {...formRow}>
                <Input
                  required="true"
                  inputType="textarea"
                  isMaterial={false}
                  name="message"
                  label="Message"
                  aria-label="message"
                  maxlength="1000"
                />
              </Box>
              <div>
                <Button type="Submit" title=" Contact Us" {...btnStyle} />
              </div>
            </form>
          </ContactForm>
        </Box>
      </Container>
    </ContactWrapper>
  );
};

// NewsletterSection style props
ContactSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  formRow: PropTypes.object
};

// NewsletterSection default style
ContactSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // button default style
  btnStyle: {
    minWidth: '152px',
    minHeight: '45px',
    fontSize: '14px',
    fontWeight: '500',
  },
  //Fom Row
  formRow: {
    mb: '10px'
  }
};

export default ContactSection;
