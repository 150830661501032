import styled from 'styled-components';

const ContactWrapper = styled.section`
  padding: 80px 0;
  overflow: hidden;
  @media (max-width: 990px) {
    padding: 40px 0 60px 0;
  }
`;

const ContactForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto;
  @media (max-width: 575px) {
    flex-direction: column;
    max-width: 100%;
  }

  
  .reusecore__button {
    flex-shrink: 0;
    transition: all 0.3s ease;
    @media (max-width: 575px) {
      width: 100%;
    }
    &:hover {
      box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
    }
  }
`;

export { ContactForm };

export default ContactWrapper;
